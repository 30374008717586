@import "partials/variables";
@import "partials/sprites";
@import "partials/_responsive_mixins";

$help-center-padding-mobile: 25px;
$help-center-padding-tablet-and-desktop: 15%;

.helpPage {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  min-height: 500px;

  & :global(.gladlyHC) {
    background-color: $color-gray-offwhite;
    margin: 0;
    max-width: none;
    padding: 35px $help-center-padding-mobile;

    @include tablet-and-desktop-breakpoint {
      padding: 35px $help-center-padding-tablet-and-desktop;
    }

    // Gladly will only render their search input on the main base route, so we built a custom search component using
    // their API instead so that we can render on all sub-routes.
    & :global(.gladlyHC-searchContainer) {
      display: none !important;
    }
  }

  & :global(.gladlyHC-searchContainer) {
    background-color: #1A1A1A;
    min-height: 150px;
    margin: 0;
    width: 100%;

    @include tablet-and-desktop-breakpoint {
      width: auto;
    }

    // Display and position the search icon svg inside the input
    &:after {
      @include reb-header_search;
      content: "";
      display: inline-block;
      position: absolute;
      right: 10px;
      top: 12px;
    }

    // The search input
    input {
      // @include body-2-size;
      // The above can replace the below
      @include font-sm;
      margin: 0 auto;
      width: 100%;

      @include tablet-and-desktop-breakpoint {
        width: 450px;
      }
    }

    // The typeahead search results dropdown
    & :global(.gladlyHC-searchMenuContainer) {
      margin-left: -2%;
      margin-top: 1px;
      left: 0;
      right: 0;
      width: 104%;

      @include tablet-and-desktop-breakpoint {
        width: 550px;
        margin-left: -75px;
      }

      & :global(.gladlyHC-searchMenu) {
        border: 1px solid $color-dark-gray;
        border-radius: 0;
        box-shadow: none;
        padding: 30px;
        width: auto;

        @include tablet-and-desktop-breakpoint {
          width: 100%;
        }
      }
      // Each individual search result row
      & :global(.gladlyHC-searchResult) {
        color: $color-dark-gray;
      }
    }
  }

  & :global(.gladlyHC-faqSection),
  & :global(.gladlyHC-searchResultsSection) {
    flex: 0 1 auto;
  }

  & :global(.gladlyHC-answersIndex-item),
  & :global(.gladlyHC-faqSection-listItem),
  & :global(.gladlyHC-searchResultsSection-result),
  & :global(.gladlyHC-searchResult) {
    margin: 8px 0;
  }

  & :global(.gladlyHC-faqContainer) {
    @include tablet-and-desktop-breakpoint {
      padding: 15px 0 60px 0;
    }
  }

  & :global(.gladlyHC-faqSection-header) {
    @include body;
    @include semibold;
    // @include body-1-size;
    // The above can replace the below
    @include font-lg;
    text-transform: uppercase;
  }

  & :global(.gladlyHC-faqSections) {
    row-gap: 35px;

    @include tablet-and-desktop-breakpoint {
      row-gap: 70px;
    }
  }

  & :global(.gladlyHC-answerSection) :global(.gladlyHC-faqSection-list),
  & :global(.gladlyHC-searchResultsSection-results),
  & :global(.gladlyHC-answersIndex-list) {
    @include body;
    // @include body-1-size;
    // The above can replace the below
    @include font-lg;

    list-style-type: none;
  }

  & :global(.gladlyHC-answersIndex-link),
  & :global(.gladlyHC-faqSection-listItem-more) {
    @include semibold;
    @include body;
    // @include body-2-size;
    // The above can replace the below
    @include font-sm;
    text-decoration-line: underline;
  }

  & :global(.gladlyHC-faqHeading) {
    display: none;
  }

  & :global(.gladlyHC-answerSection) :global(.gladlyHC-faqSection-header),
  & :global(.gladlyHC-searchResultsSection-heading) {
    @include heading;
    // @include heading-2-size;
    // The above can replace the below
    font-size: 36px;
    text-transform: none;
  }

  & :global(.gladlyHC-answerDetail),
  & :global(.gladlyHC-answersIndex-link) {
    margin-bottom: 100px;
  }

  & :global(.gladlyHC-answerSection) :global(.gladlyHC-faqSection-header),
  & :global(.gladlyHC-answerDetail-name),
  & :global(.gladlyHC-searchResultsSection-heading) {
    margin-bottom: 35px;
  }

  & :global(.gladlyHC-answerDetail-name) {
    @include heading;
    // @include heading-3-size;
    // The above can replace the below
    @include font-xxl;
  }

  & :global(.gladlyHC-answerDetail-backLink) {
    @include body;
    @include semibold;
    // @include body-2-size;
    // The above can replace the below
    @include font-sm;
    background: $color-dark-gray;
    color: $color-white;
    height: 44px;
    margin-top: 70px;
    order: 5;
    padding: 10px 16px;
    text-align: center;
    width: 148px;
  }

  & :global(.gladlyHC-answerDetail-content) {
    strong {
      text-decoration: revert;
      font-weight: revert;
    }
  
    a {
      text-decoration: revert;
    }
  }
  
}

.searchContainerHeader {
  align-items: center;
  background-color: #1A1A1A;
  color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 60px 25px 0 25px;

  @include tablet-and-desktop-breakpoint {
    padding: 60px 15% 0 15%;
  }

  h3 {
    @include heading;
    // @include heading-3-size;
    // The above can replace the below
    @include font-xxl;
    margin: 0 0 20px 0;
  }

  p {
    // @include body-1-size;
    // The above can replace the below
    @include font-lg;

    margin-bottom: 25px;
  }

  & :global(.reb-main_nav_search_ico) {
    bottom: -20px;
    position: absolute;
    right: 20px;
  }
}

.breadCrumbs {
  // @include body-3-size
  // The above can replace the below
  @include font-xs;
  background-color: $color-gray-offwhite;
  list-style-type: none;
  padding: 35px 25px 0 25px;

  @include tablet-and-desktop-breakpoint {
    padding: 35px 15% 0 15%;
  }

  li {
    display: inline-block;
  }
}

.breadCrumbHelpCenter {
  color: $color-content-secondary;
}

.breadCrumbDelimiter {
  margin: 0 10px;
}

.breadCrumbCurrentPage {
  color: $color-dark-gray;
}